<template>
  <section>
    <VToolbar
      class="pt-2 pb-2"
      color="primary"
      flat
      dense
      dark
    >
      <VToolbarTitle id="toolbarTitle">
        {{ pageTitle }}
      </VToolbarTitle>
    </VToolbar>
    <section id="main">
      <section id="content">
        <VCard>
          <VAvatar
            tile
            :size="40"
          >
            <img
              :src="require('../assets/images/icon/contact/call.svg')"
              alt="Call"
            >
          </VAvatar>
          <div class="textTitleMain text-uppercase">
            Contact
          </div>
          <div class="content">
            <p class="textSubTitle">
              +91-9674869879
            </p>
            <p class="textSubTitle">
              contact@futurelaureates.com
            </p>
          </div>
        </VCard>
        <VCard>
          <VAvatar
            tile
            :size="40"
          >
            <img
              :src="require('../assets/images/icon/contact/address.svg')"
              alt="Address"
            >
          </VAvatar>
          <div class="textTitleMain text-uppercase">
            Address
          </div>
          <div class="content">
            <p class="textSubTitle">
              38 Cantonment, Park Road Airport Area Barrackpore,
              North 24 Parganas, West Bengal 700120
            </p>
          </div>
        </VCard>
        <VCard>
          <VAvatar
            tile
            :size="40"
          >
            <img
              :src="require('../assets/images/icon/contact/hours.svg')"
              alt="Address"
            >
          </VAvatar>
          <div class="textTitleMain text-uppercase">
            Office Hours
          </div>
          <div class="content">
            <p class="textSubTitle">
              Monday – Friday
            </p>
            <p class="textSubTitle">
              8:30 AM to 1:30 PM
            </p>
          </div>
        </VCard>
      </section>
    </section>
  </section>
</template>

<script>
export default {
  data: () => ({
    pageTitle: 'contact school',
  }),
};
</script>

<style lang="stylus" scoped>
@import '../assets/stylus/text';
@import '../assets/stylus/contact';
</style>
